/* global graphql */
import React from 'react';
import HeaderTech from '../../components/header/header-tech';
import TemplateWrapper from '../../components/TemplateWrapper';
import InfoSectionSplit from '../../components/InfoSection/InfoSectionSplit';
import CTAForm from '../../components/CTAForm';
import ImageTextOverlay from '../../components/ImageTextOverlay';
import { Link } from "gatsby";
import { graphql } from "gatsby";

const TechCellPage = props =>
  (<TemplateWrapper location={props.location}>
    <HeaderTech location={props.location.pathname} />
    <main className="TechPage TechCellPage">
      <section className="Section TechPage-heading">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row">
              <div className="col-xs-14 col-md-12 col-md-offset-1 col-lg-8">
                <h1 className="Heading Heading-d1 light TechPage-heading-title">Magic Cellular</h1>
                <p className="BodyText BodyText-B1">Magic changes how devices move between cellular base stations and introduces seamless handoff between WiFi and cellular networks.</p>
                <div className="TechPage-heading-cta">
                  <Link to="/waitlist/" className="BtnFilled BtnFilled-large purple uppercase" onClick={()=>window.analytics.track('Clicked Waitlist CTA',{component:'techCellPage'})}><span>Sign up</span></Link>
                  <p className="Caption Caption-C3 light uppercase">Available late 2019</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Section TechPage-info1">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row">
              <div className="col-xs-14 col-md-12 col-md-offset-1">
                <InfoSectionSplit data={props.data.allDataJson.edges[0].node.TechCellInfo1} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Section TechPage-info2">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row">
              <div className="col-xs-14 col-md-12 col-md-offset-1">
                <InfoSectionSplit data={props.data.allDataJson.edges[0].node.TechCellInfo2} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Section TechPage-pic1">
        <ImageTextOverlay imageLink="https://s3.us-east-2.amazonaws.com/hologram-static/magic/marketing/tech_cell_road-2.png" imageText="Available globally in 2019" />
      </section>
      <section className="Section TechPage-info3">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row">
              <div className="col-xs-14 col-md-12 col-md-offset-1">
                <InfoSectionSplit data={props.data.allDataJson.edges[0].node.TechCellInfo3} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Section TechPage-CTAForm">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row">
              <div className="col-xs-14 col-md-12 col-md-offset-1">
                <CTAForm text="Sign up now to try out Magic Cellular for yourself and your devices. Available for free this Autumn." />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </TemplateWrapper>);

export default TechCellPage;

export const pageQuery = graphql`
  query TechCellQuery {
    allDataJson {
      edges {
        node {
          TechCellInfo1 {
            title,
            subtitle,
            paragraph1,
            paragraph2,
            paragraph3
            color
          }
          TechCellInfo2 {
            title,
            subtitle,
            paragraph1,
            paragraph2,
            color
          }
          TechCellInfo3 {
            title,
            subtitle,
            paragraph1,
            paragraph2,
            color
          }
        }
      }
    }
  }
`;

